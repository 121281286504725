.googleReviewContainer {
  margin-right: 20px;
}

.googleReviewContainer .header {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.googleReviewContainer .form {
  margin-bottom: 20px;
}

.googleReviewContainer .field-container {
  margin-bottom: 15px;
}

.googleReviewContainer .label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.googleReviewContainer .error {
  color: red;
  margin-top: 5px;
}

.googleReviewContainer .submit-button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #edb139;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
}

.googleReviewContainer .review-list {
  margin-top: 20px;
}

.googleReviewContainer .review-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px 0;
  padding: 15px;
  background-color: #f9f9f9;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.googleReviewContainer .review-item h3 {
  font-size: clamp(1rem, 0.6875rem + 1vw, 1.25rem);
  overflow: scroll;
}

.googleReviewContainer .button-container {
  margin-top: 10px;
}

.googleReviewContainer .edit-button {
  margin-right: 10px;
  padding: 8px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.googleReviewContainer .delete-button {
  padding: 5px 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .googleReviewContainer {
    padding: 15px;
  }

  .googleReviewContainer .input,
  .googleReviewContainer .submit-button,
  .googleReviewContainer .edit-button,
  .googleReviewContainer .delete-button {
    width: 100%;
    margin-bottom: 10px;
  }
}

.service-container .input-field {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.service-container .error {
  color: red;
  font-size: 0.875em;
}

.service-container .service-model {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
}

.service-container .dashed-border {
  border: 1px dashed #ccc;
  padding: 10px;
  margin: 5px 0;
}

.service-container .flex {
  display: flex;
  align-items: center;
}

.service-container .flex-button {
  margin-left: 10px;
  color: white;
  background-color: red;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.service-container .flex-button:hover {
  background-color: darkred;
}

.service-container button {
  margin: 10px 0;
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.service-container button:disabled {
  background-color: #ccc;
}

/* const handleSubmit = async (e) => {
  e.preventDefault();

  const formData = new FormData();
  formData.append("image", mainImage);
  formData.append("servicename", servicename);
  formData.append("description", description);

  const modelsToSend = serviceModel.map((model) => {
    const modelData = {
      title: model.title,
      description: [model.description],
      requirements: model.requirements,
    };

    // Append model image if it exists
    if (model.image) {
      formData.append(`modelImages[${model.title}]`, model.image);
    }

    return modelData;
  });

  formData.append("serviceModel", JSON.stringify(modelsToSend));

  try {
    const response = await axiosInstance.post("/api/add-service", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("Service created:", response.data);
    resetForm();
  } catch (error) {
    console.error("Error creating service:", error.response.data);
  }
}; */

/* update servcie model  */

.requirement-section {
  margin-bottom: 15px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");

:root {
  --header-height: 3rem;
  --nav-width: 68px;
  --first-color: #edb139;
  --first-color-light: #ffffff;
  --white-color: #ffffff;
  --body-font: "Nunito", sans-serif;
  --normal-font-size: 1rem;
  --z-fixed: 100;
}

html,
body {
  margin: 0;
  padding: 0;
  max-height: 100vh !important;
  font-family: "Radio Canada", sans-serif;
  overflow-x: hidden !important;
}
*,
::before,
::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}
